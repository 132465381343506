import { terms } from '~/enums'

export const useGetTermByWeek = (week: string) => {
  const weekNumber = parseInt(week.split('-')[1], 10)
  if (weekNumber >= 40 && weekNumber <= 52) {
    return terms.FIRST // Starts from October (weekNumber 40) to end December (weekNumber 52)
  } else if (weekNumber >= 1 && weekNumber <= 15) {
    return terms.SECOND // January (weekNumber 1) to April (weekNumber 15)
  } else if (weekNumber >= 15 && weekNumber <= 39) {
    return terms.THIRD // April (weekNumber 28) to July (weekNumber 39)
  } else {
    return terms.THIRD // Ghana should not have a fourth term actually
  }
}
