import { defineStore } from 'pinia'
import type { terms } from '~/enums'

export interface GamesStore {
  games: Record<string, GameSpecification[]>
}

export const useNumbersGamesStore = defineStore('numbersGamesStore', {
  state: () => ({
    NUMBER_OF_LEVELS_TO_UNLOCK: 5,
    NUMBER_OF_QUESTIONS_PER_LEVEL: 10,
    gameProgress: {
      'term-1': [] as GameProgress[],
      'term-2': [] as GameProgress[],
      'term-3': [] as GameProgress[],
    } as Record<string, GameProgress[]>,
    games: {
      'term-1': [] as GameSpecification[],
      'term-2': [] as GameSpecification[],
      'term-3': [] as GameSpecification[],
    } as Record<string, GameSpecification[]>,
  }),
  getters: {
    gameSpecifications: (state) => {
      return (term: terms) => state.games[term] as GameSpecification[]
    },
    gameSpecificationsGroupedByContentStandard: (state) => {
      return (term: terms) =>
        Object.values(
          state.games[term].reduce<Record<string, GameSpecification[]>>(
            (acc, spec) => {
              const key = spec.gameReference.contentStandard
              if (!acc[key]) {
                acc[key] = []
              }
              acc[key].push(spec)
              return acc
            },
            {},
          ),
        ) as GameSpecification[][]
    },
    gameSpecificationById: (state) => {
      return (term: terms, gameId: string) =>
        state.games[term]?.find(
          (gameSpecification) => gameSpecification.gameId === gameId,
        )
    },
    gameSpecificationByWeek: (state) => {
      return (week: string) => {
        const term = useGetTermByWeek(week)
        return state.games[term].filter(
          (gameSpecification) => gameSpecification.gameReference.week === week,
        ) as GameSpecification[]
      }
    },
    gameProgressById: (state) => {
      return (term: terms, gameId: string) =>
        state.gameProgress[term].find(
          (gameProgress) => gameProgress.gameId === gameId,
        )
    },
  },
  actions: {
    async initializeNumberGames(term: terms) {
      try {
        const fetchedGames = await queryContent(`/mathematics/term-2`).find()
        if (fetchedGames.length) {
          this.games[term] = fetchedGames.map((game) => {
            return {
              gameReference: {
                contentStandard: game.game_reference?.content_standard,
                indicator: game.game_reference?.indicator,
                subject: game.game_reference?.subject,
                strand: game.game_reference?.strand,
                substrand: game.game_reference?.substrand,
                week: game._dir,
              } as GameReference,
              gameId: game.game_id,
              gameName: game.game_name,
              gameDescription: game.game_description,
              unlocked: game.unlocked,
              gameProperties: {
                gameType: game.game_properties?.game_type ?? '',
                operators: game.game_properties?.operators ?? [],
                visualType: game.game_properties?.visual_type ?? '',
                arrayLength: game.game_properties?.array_length,
                stepSize: game.game_properties?.step_size,
                groupSize: game.game_properties?.group_size,
                useOperatorName:
                  game.game_properties?.use_operator_name ?? false,
              } as GameProperty,
              gameLevels:
                game.game_levels?.map((level: any) => {
                  return {
                    level: level.level ?? 1,
                    range: level.range,
                    questionTypes: level.question_types,
                    uniqueShapes: level.unique_shapes,
                    shapes: level.shapes,
                    expressionsPerQuestion: level.expressions_per_question,
                  } as GameLevel
                }) ?? [],
              coreCompetences: game.core_competences ?? [],
            } as GameSpecification
          })
        }
      } catch (error) {
        console.error('Error fetching game content:', error)
      }
    },
    async syncGameProgress(
      gameReference: GameReference,
      term: terms,
      userId: string,
    ) {
      if (!userId || !gameReference || this.gameProgress[term]?.length > 0) {
        return // Only users may register history and prevent unnessary calls when gameProgress is already filled
      }
      const { data } = await $fetch<{ data: GameProgressRecord[] }>(
        '/api/gameProgress/gameProgress',
        {
          method: 'GET',
          params: {
            userId,
            subject: gameReference.subject,
            strand: gameReference.strand,
            substrand: gameReference.substrand,
          },
        },
      )
      const gameProgressRecords = data as GameProgressRecord[]
      if (!gameProgressRecords || !gameProgressRecords.length) {
        return
      }
      this.loadGameProgressRecords(term, gameProgressRecords)
    },
    setAllgameProgressToZero() {
      Object.keys(this.gameProgress).forEach((term) => {
        this.gameProgress[term] = this.gameProgress[term].map(
          (gameProgress: GameProgress) => ({
            gameId: gameProgress.gameId,
            level: 0,
            levelCompletion: 0,
            unlocked: false,
          }),
        )
      })
    },
    unlockNextGameIfAllowed(term: terms, gameProgress: GameProgress) {
      if (gameProgress.level >= this.NUMBER_OF_LEVELS_TO_UNLOCK) {
        const index = this.gameProgress[term].findIndex(
          (progress: GameProgress) => progress.gameId === gameProgress.gameId,
        )
        // @todo unlocking next game expects next game to be in gameProgress but not the case at the moment
        this.gameProgress[term][index + 1].unlocked = true
      }
    },
    updateUnlockedGames(term: terms) {
      const gameSpecifications = this.games[term]
      // When gameScore is present for a game unlock the next game
      gameSpecifications.forEach((gameSpecification: GameSpecification) => {
        const gameProgress = this.gameProgress[term].find(
          (gameProgress) => gameProgress.gameId === gameSpecification.gameId,
        )
        if (!gameProgress) return
        this.unlockNextGameIfAllowed(term, gameProgress)
      })

      // Unlock the first game of every content
      if (this.gameProgress[term].length > 0) {
        const firstGame = this.gameProgress[term][0]
        if (!firstGame.unlocked) {
          firstGame.unlocked = true
        }
      }
    },
    loadGameProgressRecords(
      term: terms,
      progressRecords: GameProgressRecord[],
    ) {
      const progressArray = this.gameProgress[term]
      for (const record of progressRecords) {
        const { gameId, level, levelCompletion } = record
        const existingProgress = progressArray.find(
          (score) => score.gameId === gameId,
        )

        if (existingProgress) {
          // Update the existing game progress
          existingProgress.level = level
          existingProgress.levelCompletion = levelCompletion
          existingProgress.unlocked = true
        } else {
          // Add a new game progress entry if not present
          progressArray.push({
            gameId,
            level,
            levelCompletion,
            unlocked: true,
          })
        }
      }
      // @todo unlock levels or games based on the progress
      // this.updateUnlockedGames(term)
    },
    increaseGameScoreLevelCompletion(
      term: terms,
      gameId: string,
      amount: number,
    ) {
      const progressList = this.gameProgress[term]
      let progress = progressList.find((p) => p.gameId === gameId)

      if (!progress) {
        // Create a new game progress if one doesn't exist
        progress = {
          gameId,
          level: 1,
          levelCompletion: amount,
          unlocked: true,
        }
        progressList.push(progress)
        return
      }

      // Increase the level completion by the given amount
      progress.levelCompletion += amount

      // If the accumulated level completion meets or exceeds the threshold
      if (progress.levelCompletion >= this.NUMBER_OF_QUESTIONS_PER_LEVEL) {
        progress.level += 1
        progress.levelCompletion = 0
        this.unlockNextGameIfAllowed(term, progress)
      }
    },
  },
})
